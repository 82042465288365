








































































































































































































import {
    Component,
    Vue,
    Watch
} from 'vue-property-decorator';

import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'
import _ from "lodash"
import test from '@/assets/test.json'
import {
    Web
} from '@/store/web'
@Component({
    components: {

    },
})
export default class Home extends Vue {
    private name:string = ""
    private phone_number:string = ""
    
    private dashboard: any = null
    private user: any = {}
    private years: any = 0
    private year:any ={}
    private data: any = null
    private lockUser: boolean = true
    $vs: any
    private e1: number = 1
    private response: boolean = false
    private suggestion: string = ''
    private answer: any = [];
    private ansIndex: number = 0;
    private resultAll: any = [];
    private tmp: any = 0
    private agencies: any = []
    private agencyType: any = []
    private chooseAgencyType: number | null = null
    private chooseAgency: number | null = null
    private dialog: boolean = true
    private dialogUser: boolean = false;
    private formUser: any = {};
    private myAgency :any = 0
    public appSetting: any = {}


    private async getStart() {
        this.dialog = false;
        //let check = confirm('คุณต้องการให้ข้อมูลส่วนตัวเพิ่มเติมหรือไม่')
        // if(check){
        //   this.dialogUser = true;
        // } 
    }
    private async useUserData() {
        this.dialogUser = false;
        let data = await Core.getHttp(`/api/ita/v2/dashboard/${this.user.ext_link.id}/`)
        this.formUser.agency = data.agency
        this.formUser.name = this.user.first_name + " " + this.user.last_name
        this.formUser.email = this.user.email
        console.log(data.agency);
        this.dialogUser = true;
    }

    @Watch('chooseAgencyType')
    private async chnageChooseAgencyType(val: number) {
        this.agencies = await Core.getHttp(`/api/ita/v2/agencys/?agency_type=${val}`)

    }
    @Watch('chooseAgency')
    private async chnageChooseAgency(val: number) {
        let check = await Core.getHttp(`/api/eit/v2/answerissueeit-report/?agency=${this.chooseAgency}&year=${this.years}&phone_number=${this.phone_number}`)
        //  alert(check.length )
        if (check.length > 0) {
            this.lockUser = true;
        } else {
            this.lockUser = false;
        }
    }

    private async addValue(val: any) {
        let e: any = document.getElementById(val);
        if (e.options[e.selectedIndex].value) {
            let value: any = e.options[e.selectedIndex].value;
            let text: any = e.options[e.selectedIndex].text;
            this.answer.push(JSON.parse(value))
            this.answer = _.uniqBy(_.reverse(this.answer), function (e: any) {
                return e.id;
            });

            this.tmp++
            // console.log(this.answer);
            await this.show()
        }

    }

    private async show() {
        let xx: any = this.answer;
        xx = await _.chain(xx)
            .groupBy('group')
            .map((value: any, key: any) => ({
                group: key,
                value: value
            }))
            .value();
        this.resultAll = xx
        console.log(xx)
    }

    private async saveAnswer() {
        await Web.switchLoad(true);
        for (let index = 0; index < this.answer.length; index++) {
            let form = {
                "value": this.answer[index].value,
                "value_type": this.answer[index].value_type,
                "value_by": this.answer[index].value_by,
                // "user": this.user.pk,
                "name": this.name,
                "phone_number": this.phone_number,
                "assessmentIssues": this.answer[index].assessment,
                "agency": this.chooseAgency,
                "year": this.years,
                "issue": this.answer[index].group,
                "issueDetail": this.answer[index].id
            }
            await Core.postHttp(`/api/eit/v2/answerissueeit/`, form)
        }
        await Core.postHttp(`/api/eit/v2/answersuggestioneit/`, {
            "suggestion": this.suggestion,
            // "user": this.user.pk,
            "name": this.name,
            "phone_number": this.phone_number,
            "agency": this.chooseAgency,
            "year": this.years,
        })
        await Web.switchLoad(false);
        await this.openNotification('top-right', 'success', `<i class="em em-smiley" aria-role="presentation" aria-label="SMILING FACE WITH OPEN MOUTH"></i>`, 'ประเมินสำเร็จแล้ว', '')
        alert('ประเมิน EIT สำเร็จแล้ว')
         await this.$router.go(-1)
    }

    public async created() {
        await Web.switchLoad(true);
        this.agencyType = await Core.getHttp(`/api/ita/v1/agencytype/`)
        this.agencyType = await _.filter(this.agencyType ,(r:any)=>{return r.id != 3})

        //this.user = await User.getUser();
        this.years = this.$route.query.year
        this.year = await Core.getHttp(`/api/eit/v2/year/${this.years}/`)
        this.data = await Core.getHttp(`/api/eit/v1/issue?year=${this.years}`)
        try {
         this.appSetting = await Core.getSetting(this.year.year)
            } catch (error) {
                this.appSetting = null
            }
        await Web.switchLoad(false);
        // this.myAgency = this.user.ext_link.agency
        this.response = true
    }

    async openNotification(position: any = null, color: any, icon: any, title: string, text: string) {

        const noti = this.$vs.notification({
            icon,
            color,
            position,
            title: title,
            text: text
        })
    }
    getChoice(choice:any, index:any, defChoices:any){
    try {
        let choices = this.appSetting.eit_choices
    let result = _.find(choices, item => _.includes(item.index, choice));
    if (result) {
        return result.choices[index]
    } else {
        return defChoices
    } 
    } catch (error) {
        return defChoices
    }
}
    async sleep(ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async toTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
}
